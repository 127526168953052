<template>
  <div class="deviceWorkerHome" :style="{ 'background-image': 'url(' + bgImg + ')' }">
    <tabs1 v-model="activeTab" :options="tabList" @change="tabChange"></tabs1>
    <div class="page-container">
      <router-view/>
    </div>
  </div>
</template>

<script>
let bgImg = require('../../../../../static/iconImage/operationCenter.png')

export default {
  name: 'deviceWorkerHome',
  data() {
    return {
      bgImg: bgImg,
      activeTab: '/deviceView1/deviceWorkerAll',
      tabList: [
        {
          title: '全部',
          path: '/deviceView1/deviceWorkerHome/deviceWorkerAll'
        },
        {
          title: '未处理',
          path: '/deviceView1/deviceWorkerHome/deviceWorkUndeal'
        },
        {
          title: '处理中',
          path: '/deviceView1/deviceWorkerHome/deviceWorker'
        },
        {
          title: '已处理',
          path: '/deviceView1/deviceWorkerHome/deviceWorkerFinished'
        }
      ]
    }
  },
  beforeDestroy() {
    this.removeLocalStorageEvent(this.updateWorkerList);
  },
  mounted() {
    this.addLocalStorageEventByKey('updateWorkerList', this.updateWorkerList)
  },
  methods: {
    updateWorkerList:function() {
      this.$bus.emit('updateWorkerList', {});
    },
    tabChange(e) {
      this.activeTab = e
      this.$router.replace(e).catch(e => {
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.deviceWorkerHome {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100%;

  .page-container {
    width: 100%;
    height: calc(100% - 32px);
  }
}


::v-deep .van-loading__text {
  color: white;
}

::v-deep .van-loading__circular {
  color: white;
}

::v-deep .van-pull-refresh__head {
  color: white;
}

::v-deep .van-list__finished-text {
  color: white;
}


</style>